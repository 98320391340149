.invoice-header {
  background-color: var(--title-color, #fff);
  color: #fff;
  padding: 1rem 1rem;
}

.invoice-header .name {
  font-size: 180%;
}

.invoice-body {
  padding-top: 1rem;
}

.title {
  color: var(--title-color, #121212);
  font-size: 180%;
  margin-bottom: 0.25rem;
  position: relative;
}

.gen-date {
  text-align: center;
}

.to-row > div {
  display: inline-block;
  vertical-align: top;
  padding-right: 1rem;
}

.to-row > div:first-child {
  width: 60%;
}

.to-row > div:nth-child(2) {
  width: 40%;
}

.items table {
  width: 100%;
}

table {
  border-collapse: collapse;
}

th,
tbody td {
  border: 1px solid var(--title-color, #121212);
}

tr.blank-row td {
  border: 0 ;
}

thead {
  background-color: var(--title-color, #121212);
  color: white;
}

td {
  text-align: center;
}

td:first-child {
  text-align: left;
}

td:last-child {
  text-align: right;
}

tfoot td {
  padding: 0.5rem;
  text-align: right !important;
}

tfoot td:last-child {
  border: 1px solid var(--title-color, #121212);
}

tfoot tr:last-child td:last-child {
  background-color: var(--title-color, #121212);
  color: white;
}

.payment-instructions {
  color: var(--title-color, #121212);
  border: 1px solid;
  padding: 1rem;
}