:root {
  --bg: #fff;
  --header-bg: #70d2ff;
  --title-color: #00395d;
  --txt-color: #364954;
}

body {
  margin: 0;
  font-family: serif, sans-serif, "Segoe UI", "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
